import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useSalesList() {
    // Use toast
    const toast = useToast();

    const refSaleListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'code', sortable: true, label: 'Código' },
        { key: 'payment_method.name', sortable: true, label: 'Método de pago' },
        { key: 'user', sortable: true, label: 'Usuario' },
        { key: 'amount', sortable: true, label: 'Monto' },
        { key: 'delivery', sortable: true, label: 'Delivery' },
        { key: 'discount', sortable: true, label: 'Descuento' },
        { key: 'totalAmount', sortable: true, label: 'Monto Total' },
        { key: 'delivery_type.name', sortable: true, label: 'Tipo de entrega' },
        { key: 'products_count', sortable: true, label: 'Cantidad de productos' },
        { key: 'state', sortable: true, label: 'Estado' },
        { key: 'createdAt', sortable: true, label: 'Fecha venta' },
        { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalSales = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);

    /**paymentMethodsFilter,
                deliveryTypesFilter,
                saleStatesFilter, */

    const rangePickerFilter = ref(null);
    const paymentMethodsFilter = ref(null);
    const deliveryTypesFilter = ref(null);
    const saleStatesFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refSaleListTable.value ? refSaleListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalSales.value,
        }
    });

    const refetchData = () => {
        refSaleListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, rangePickerFilter, paymentMethodsFilter, deliveryTypesFilter, saleStatesFilter], () => {
        refetchData();
    });

    const fetchSales = (ctx, callback) => {
        store
            .dispatch('app-sale/fetchSales', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                range: rangePickerFilter.value,
                paymentmethods: paymentMethodsFilter.value,
                deliverytypes: deliveryTypesFilter.value,
                salestates: saleStatesFilter.value
            })
            .then(response => {
                const { sales, total } = response.data;
                callback(sales);
                totalSales.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de las ventas',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchSales,
        tableColumns,
        perPage,
        currentPage,
        totalSales,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refSaleListTable,

        refetchData,

        // Extra Filters
        rangePickerFilter,
        paymentMethodsFilter,
        deliveryTypesFilter,
        saleStatesFilter
    }
}