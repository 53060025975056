<template>

    <div>

		<!-- Filters -->
        <sales-list-filters
            :range-picker-filter.sync="rangePickerFilter"
            :payment-methods-filter.sync="paymentMethodsFilter"
            :delivery-types-filter.sync="deliveryTypesFilter"
            :sale-states-filter.sync="saleStatesFilter"
            :payment-method-options="paymentMethodOptions"
            :delivery-type-options="deliveryTypeOptions"
            :sale-state-options="saleStateOptions"
        />

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row align-h="between">

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="4">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block"
                                placeholder="Buscar por código de venta ..."
                            />
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table
                ref="refSaleListTable"
                class="position-relative"
                :items="fetchSales"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc">

                <!-- Column: Code -->
                <template #cell(code)="data">
                    <b-link
                        :to="{ name: 'sale-details', params: { id: data.item._id } }"
                        class="font-weight-bold d-block text-nowrap">
                        {{ data.item.code}}
                    </b-link>
                </template>

                <!-- Column: Amount -->
                <template #cell(amount)="data">
                    S/ {{ parseFloat(data.item.amount.$numberDecimal).toFixed(2) }}
                </template>

                <!-- Column: Delivery -->
                <template #cell(delivery)="data">
                    <span v-if="data.item.delivery">S/ {{ parseFloat(data.item.delivery.$numberDecimal).toFixed(2) }}</span>
                    <span v-else>---</span>
                </template>

                <!-- Column: Discount -->
                <template #cell(discount)="data">
                    <span v-if="data.item.discount">S/ {{ parseFloat(data.item.discount.$numberDecimal).toFixed(2) }}</span>
                    <span v-else>---</span>
                </template>

                <!-- Column: Total Amount -->
                <template #cell(totalAmount)="data">
                    S/ {{ parseFloat(data.item.totalAmount.$numberDecimal).toFixed(2) }}
                </template>

                <!-- Column: State -->
                <template #cell(state)="data">
                    <b-badge
                        pill
                        :variant="`light-${data.item.sale_state.color}`"
                    >
                        {{ data.item.sale_state.name }}
                    </b-badge>
                </template>

                <!-- Column: CreatedAt -->
                <template #cell(createdAt)="data">
                    {{ data.item.createdAt ? formatDate(data.item.createdAt, 'DD/MM/YYYY HH:mm:ss') : '---' }}
                </template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item :to="{ name: 'sale-details', params: { id: data.item._id } }">
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">Ver Detalles</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="changeSaleState(data.item._id, aprobadoId, 'APROBAR')" v-if="data.item.sale_state.name == 'POR VERIFICAR'">
                            <feather-icon icon="CheckCircleIcon" />
                            <span class="align-middle ml-50">APROBAR</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="changeSaleState(data.item._id, rechazadoId, 'RECHAZAR')" v-if="data.item.sale_state.name == 'POR VERIFICAR'">
                            <feather-icon icon="SlashIcon" />
                            <span class="align-middle ml-50">RECHAZAR</span>
                        </b-dropdown-item>

                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalSales"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable } from 'bootstrap-vue';
    import vSelect from 'vue-select';
    import store from '@/store';
    import VueSweetalert2 from 'vue-sweetalert2';
    import { onUnmounted, ref } from '@vue/composition-api';
    import SalesListFilters from './SalesListFilters.vue';
    import useSalesList from './useSalesList';
    import saleStoreModule from '../saleStoreModule';
    import moment from 'moment';
    import axios from 'axios';
    import Vue from 'vue';
    import 'animate.css';

    Vue.use(VueSweetalert2);

    export default {
        components: {
            SalesListFilters,

            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,

            vSelect
        },
        data () {
            return {
                aprobadoId: '',
                rechazadoId: '',

                paymentMethodOptions: [],
                deliveryTypeOptions: [],
                saleStateOptions: []
            }
        },
        beforeMount (){
            this.getPaymentMethods();
            this.getDeliveryTypes();
            this.getSaleStates();
        },
        methods: {
            async getPaymentMethods (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/payment-methods/select`);
                let data = [];
                response.data.map(item => {
                    data.push({ label: item.name, value: item._id });
                });
                this.paymentMethodOptions = data;
            },
            async getDeliveryTypes (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/delivery-types/select`);
                let data = [];
                response.data.map(item => {
                    data.push({ label: item.name, value: item._id });
                });
                this.deliveryTypeOptions = data;
            },
            async getSaleStates (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/sale-states/select`);
                let data = [];
                response.data.map(item => {
                    if (item.name == 'APROBADO'){
                        this.aprobadoId = item._id;
                    }
                    if (item.name == 'RECHAZADO'){
                        this.rechazadoId = item._id;
                    }
                    data.push({ label: item.name, value: item._id });
                });
                this.saleStateOptions = data;
            },
            formatDate (date, format){
                return moment(date).format(format);
            }
        },
        setup() {

            // Use toast
            const toast = useToast();

            const SALE_APP_STORE_MODULE_NAME = 'app-sale';

            // Register module
            if (!store.hasModule(SALE_APP_STORE_MODULE_NAME)) store.registerModule(SALE_APP_STORE_MODULE_NAME, saleStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(SALE_APP_STORE_MODULE_NAME)) store.unregisterModule(SALE_APP_STORE_MODULE_NAME);
            });

            const {
                fetchSales,
                tableColumns,
                perPage,
                currentPage,
                totalSales,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refSaleListTable,
                refetchData,

                // Extra Filters
                rangePickerFilter,
                paymentMethodsFilter,
                deliveryTypesFilter,
                saleStatesFilter
            } = useSalesList();

            const changeSaleState = (id, stateId, stateNameQuestion) => {
                Vue.swal({
                    title: `¿Estás seguro de ${stateNameQuestion} la venta?`,
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: `Sí, ${stateNameQuestion.toLowerCase()}!`,
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        store.dispatch('app-sale/changeSaleState', { id, stateId })
                            .then( (response) => {
                                refetchData();
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( () => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al cambiar el estado de la venta',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {

                // Sidebar

                fetchSales,
                tableColumns,
                perPage,
                currentPage,
                totalSales,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refSaleListTable,
                refetchData,

                // Extra Filters
                rangePickerFilter,
                paymentMethodsFilter,
                deliveryTypesFilter,
                saleStatesFilter,

                // Filter
                changeSaleState
            }
        }
    }

</script>

<style lang="scss" scoped>

    .media {
        align-items: center;
    }

    .per-page-selector {
        width: 90px;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>